import api from './Api';

interface IDispositivo {
    id: string;
    nome: string;
    tipo: string;
    local: string;
    sistema_id: string;
    alarmes: any[];
    notify: string;
}

export type Dispositivo = IDispositivo;

export default {
    getByCliente: async (client_id: string): Promise<Dispositivo[]> => {
        return api.get(`/dispositivo?client_id=${client_id}`).then((response) => response.data);
    },
    create: async (id: string, nome: string, tipo: string, local: string, sistema_id: string, alarmes?: string, notify?: string) => {
        return api.post(`/dispositivo`, { 'id': id, "nome": nome, "tipo": tipo, "local": local, "sistema_id": sistema_id, 'alarmes': alarmes ?? [], 'notify': notify ?? ''});
    },
    update: async (id: string, nome: string, tipo: string, local: string, sistema_id: string, alarmes?: string, notify?: string) => {
        return api.put(`/dispositivo`, { 'id': id, "nome": nome, "tipo": tipo, "local": local, "sistema_id": sistema_id, 'alarmes': alarmes ?? [], 'notify': notify ?? ''});
    },
    updateField: async (id: string, field: string, value: any) => {
        return api.put(`/dispositivo`, { id, field, value });
    },
    delete: async (id: string) => {
        return api.delete(`/dispositivo?id=${id}`);
    },
    getSaude: async (id: string) => {
        return api.get(`/dispositivo/saude?id=${id}`).then((response) => response.data);
    },
    getSaudeByClient: async (client_id: string) => {
        return api.get(`/dispositivo/saude?client_id=${client_id}`).then((response) => response.data);
    }
}