import api from './Api';

interface Cliente {
    id: string;
    nome: string;
    contrato: string;
}
export type { Cliente };

export default {
    getAll: async () => {
        return api.get(`/cliente`);
    }
}