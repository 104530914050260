import api from './Api';
import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: 'AKIASOED2MDTF3D7QKMF',
    secretAccessKey: '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',
    region: 'us-east-1'
});
const s3 = new AWS.S3();

interface GrupoDispositivo {
    id: string,
    nome: string,
    dispositivos: Array<string>,
    client_id: string,
    sistema_id: string
}

export type { GrupoDispositivo };

export default {
    getAll: async (cliente_id: string) : Promise<GrupoDispositivo[]> => {
        return api.get(`/grupo-dispositivos?client_id=${cliente_id}`).then((response) => response.data);
    },
    get: async (id: string) => {
        return api.get(`/grupo-dispositivos?id=${id}`);
    },
    create: async (cliente_id: string, nome: string, dispositivos: Array<string>) => {
        return api.post('/grupo-dispositivos', { nome, dispositivos, client_id: cliente_id });
    },
    update: async (grupo: GrupoDispositivo) => {
        return api.put(`/grupo-dispositivos`, grupo);
    },
    delete: async (id: string) => {
        return api.delete(`/grupo-dispositivos?id=${id}`);
    },
    uploadImage: async (grupo: GrupoDispositivo, file: File) => {
        const params = {
            Bucket: 'painel-proativa',
            Key: `STATIC/${grupo.sistema_id}/${grupo.id}`,
            Body: file,
            ACL: 'public-read' // if you want the file to be publicly accessible
        };

        return new Promise((resolve, reject) => {
            s3.upload(params, (err: any, data: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    },
    getSaude: async (grupo_id: string): Promise<any> => {
        return api.get(`/grupo-dispositivos/saude?id=${grupo_id}`).then((response) => response.data);
    }
}