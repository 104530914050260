import React, { useState } from 'react';
import GlobalDataRepo, { GlobalData } from '../../../Services/GlobalDataRepo';
import GrupoDispositivosRepo from '../../../Services/GrupoDispositivosRepo';
import { AppBar, Box, Card, CardContent, CardMedia, CircularProgress, Grid, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { useSistemaSelecionado } from '../../../Contexts/BaseContext';
import { useQuery } from '@tanstack/react-query';
import DispositivoRepo from '../../../Services/DispositivoRepo';

export default function Ativos() {
    const { sistemaSelecionado } = useSistemaSelecionado();
    const [grupoDispositivoSelected, setGrupoDispositivoSelected] = useState<string>(window.localStorage.getItem('last_grupo_dispositivo_id') ?? "");
    const [imageSrc, setImageSrc] = useState(`https://painel-proativa.s3.amazonaws.com/STATIC/${sistemaSelecionado}/${grupoDispositivoSelected}`);

    const handleImageError = () => {
        setImageSrc("/Img/motor_placeholder.png");
    };

    return (<>
        <AppBar position="static" sx={{ backgroundColor: '#fff' }} elevation={0}>
            <Toolbar>
                <GrupoDispositivoPicker
                    sistemaSelecionado={sistemaSelecionado}
                    grupoDispositivoSelected={grupoDispositivoSelected}
                    setGrupoDispositivoSelected={setGrupoDispositivoSelected}
                />

                <Box flexGrow={1}></Box>

            </Toolbar>
        </AppBar>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ margin: "20px" }}>
                    <CardMedia
                        component="img"
                        image={imageSrc}
                        alt="Image description"
                        onError={handleImageError}
                    />
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
                <Box overflow={"auto"} margin={"15px"}>
                    <GrupoDispositivosLatestData grupoDispositivoId={grupoDispositivoSelected} />
                </Box>
            </Grid>
        </Grid>
    </>
    );
}

function GrupoDispositivosLatestData(props: { grupoDispositivoId: string }) {
    const { isPending, error, data, isFetching } = useQuery({
        queryKey: ['LatestGlobalDataGrupo', props.grupoDispositivoId],
        queryFn: () => GlobalDataRepo.LatestGlobalDataByGrupoDispositivo(props.grupoDispositivoId),
        refetchInterval: 120000,
        refetchIntervalInBackground: true,
        enabled: !!props.grupoDispositivoId,
        retry: false,
    })

    if (isPending || isFetching) return <Typography color='black'>Carregando...</Typography>

    if (error || !data) return <Typography color='black'>----</Typography>

    return (
        <Stack margin={2} spacing={2}>
            {data.map((data) => (
                <DispositivoCard {...data} />
            ))}
        </Stack>
    )
}

function DispositivoCard(data: GlobalData) {
    return (
        <Card key={data.dispositivo_id}>
            <CardContent>
                <Typography key={data.dispositivo_id+"_title"} variant="h5" component="div">
                    {data.tag}
                </Typography>
                <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Eixo X</TableCell>
                                <TableCell>Eixo Y</TableCell>
                                <TableCell>Eixo Z</TableCell>
                                <TableCell>Saúde</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={data.dispositivo_id + "_mms"}>
                                <TableCell>{data['Horizontal(mm/s)'].toFixed(2)} mm/s</TableCell>
                                <TableCell>{data['Vertical(mm/s)'].toFixed(2)} mm/s</TableCell>
                                <TableCell>{data['Axial(mm/s)'].toFixed(2)} mm/s</TableCell>
                                <SaudeDispositivoTableCell dispositivo_id={data.dispositivo_id} />
                            </TableRow>

                            <TableRow key={data.dispositivo_id + "_g"}>
                                <TableCell>{data['Horizontal(g)']} g</TableCell>
                                <TableCell>{data['Vertical(g)']} g</TableCell>
                                <TableCell>{data['Axial(g)']} g</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
}

function SaudeDispositivoTableCell(props: { dispositivo_id: string }) {
    const { isPending, error, data, isFetching } = useQuery({
        queryKey: ['SaudeDispositivo', props.dispositivo_id],
        queryFn: () => DispositivoRepo.getSaude(props.dispositivo_id),
        refetchInterval: 120000,
        refetchIntervalInBackground: true,
        enabled: !!props.dispositivo_id,
    })

    if (isPending || isFetching) return <CircularProgress />

    if (error || (!data && (data.max_criticidade??0) < 0)) return <Typography color='black'>----</Typography>

    if (data.max_criticidade === 0){
        return <TableCell style={{ backgroundColor: 'green', color: "white" }}>Saudável</TableCell>
    } else if (data.max_criticidade === 1){
        return <TableCell style={{ backgroundColor: 'yellow', color: "white" }}>Atenção</TableCell>
    } else if (data.max_criticidade === 2){
        return <TableCell style={{ backgroundColor: 'orange', color: "white" }}>Alerta</TableCell>
    } else {
        return <TableCell style={{ backgroundColor: 'red', color: "white" }}>Crítico</TableCell>
    }
}

function GrupoDispositivoPicker(props: { sistemaSelecionado: string | null, grupoDispositivoSelected: string, setGrupoDispositivoSelected: Function }) {
    const { isPending, error, data, isFetching } = useQuery({
        queryKey: ['CadastroGrupoDispositivos', props.sistemaSelecionado],
        queryFn: () => GrupoDispositivosRepo.getAll(props.sistemaSelecionado ?? ""),
        enabled: !!props.sistemaSelecionado,
    })

    if (isPending || isFetching) return <Typography color='black'>Carregando...</Typography>

    if (error) return <Typography color='black'>Ocorreu um erro: {error.message}</Typography>

    return (
        <Select
            value={props.grupoDispositivoSelected}
            onChange={
                (value) => {
                    props.setGrupoDispositivoSelected(value.target.value?.toString());
                    window.localStorage.setItem('last_grupo_dispositivo_id', value.target.value?.toString() ?? "");
                }
            }
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
        >
            <MenuItem value="" disabled>
                Ecolha um Grupo
            </MenuItem>
            {data && data.map((grupoDispositivo, index) => (
                <MenuItem key={index} value={grupoDispositivo.id}>
                    {grupoDispositivo.nome}
                </MenuItem>
            ))}
        </Select>
    )
}