import api from './Api';

interface Condition {
    comparator: "==" | "!=" | ">" | "<" | ">=" | "<=";
    type: string;
    value: string;
}

interface Trigger {
    conditions: Condition[];
    logic: string;
}

interface CadastroAlarme {
    id: string;
    nome: string;
    descricao: string;
    ativo: boolean;
    criticidade: number;
    triggers: Trigger[];
    sistema_id: string;
}
export type { CadastroAlarme, Trigger, Condition };

export default {
    getAllByCliente: async (clienteId: string): Promise<CadastroAlarme[]> => {
        return api.get(`/alarmes?sistema_id=${clienteId}`).then(res => res.data);
    },
    create: async (alarme: CadastroAlarme) => {
        return api.post(`/alarmes`, alarme);
    },
    update: async (alarme: CadastroAlarme) => {
        return api.put(`/alarmes`, alarme);
    },
    delete: async (alarmeId: string) => {
        return api.delete(`/alarmes?alarme_id=${alarmeId}`);
    }
}