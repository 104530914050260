import React from 'react';
import { Card, CardContent, Typography, CardActions, Button, Box, Grid, CircularProgress } from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts';
import { useNavigate } from 'react-router-dom';
import VisaoGeralRepo, { DeviceData } from '../../../Services/VisaoGeralRepo';
import Toast from '../../../Services/Toast';
import GrupoDispositivosRepo, { GrupoDispositivo } from '../../../Services/GrupoDispositivosRepo';
import { useSistemaSelecionado } from '../../../Contexts/BaseContext';
import { useQuery } from '@tanstack/react-query';
import DispositivoRepo from '../../../Services/DispositivoRepo';

interface ISetorCard {
    title: string;
    content: { [key: string]: number };
    onClick: Function;
}

const pieParams = { height: 200, margin: { right: 5, bottom: 50 } };

export default function SaudePlanta() {

    return <>
        <SaudePlantaGauges />
    </>
}

function SaudePlantaGauges() {

    const { sistemaSelecionado } = useSistemaSelecionado();

    const { data: grupos = [], isLoading: isLoadingGrupos, isFetching: isFetchingGrupos, error } = useQuery({
        queryKey: ['grupos', sistemaSelecionado],
        queryFn: () => GrupoDispositivosRepo.getAll(sistemaSelecionado!).catch((err: any) => {
            if (err.response?.status === 403) {
                Toast.error("Você não tem permissão para acessar essa página!");
            } else {
                Toast.error("Erro ao carregar grupos de dispositivos");
            }
        }),
        enabled: !!sistemaSelecionado,
        staleTime: Infinity
    });

    if (isLoadingGrupos) {
        return <CircularProgress />
    }

    if (grupos.length === 0) {
        return <h1>Nenhum grupo de dispositivos encontrado</h1>
    }

    if (!grupos || error)
        return <h1>Erro ao carregar grupos de dispositivos</h1>

    return (
        <Box padding={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3} key={"TODOS"}><CardGaugeAll /></Grid>
                {grupos.map((grupo, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <CardGauge grupoDispositivoId={grupo.id} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

function CardGauge({ grupoDispositivoId }: { grupoDispositivoId: string }) {
    const navigate = useNavigate();

    const { data, isLoading, isFetching, error } = useQuery({
        queryKey: ['grupoSaude', grupoDispositivoId],
        queryFn: () => GrupoDispositivosRepo.getSaude(grupoDispositivoId!).catch((err: any) => {
            if (err.response?.status === 403) {
                Toast.error("Você não tem permissão para acessar essa página!");
            } else {
                Toast.error("Erro ao carregar grupos de dispositivos");
            }
        }),
        enabled: !!grupoDispositivoId,
        staleTime: Infinity,
        refetchInterval: 120000,
        refetchIntervalInBackground: true,
    });

    if (isLoading || isFetching) {
        return <CircularProgress />
    }

    if (!data || error) {
        return <h1>Erro ao carregar dados do grupo</h1>
    }

    let content: any = {};

    Object.keys(data.saude).forEach((dispositivo_id) => {
        let key = data.saude[dispositivo_id].saude;
        if (!content[key]) {
            content[key] = 0;
        }
        content[key] += 1;
    });

    return <SetorCard title={data.nome} content={content} onClick={() => {
        window.localStorage.setItem('last_grupo_dispositivo_id', data.id); navigate(`/ativos`)
    }} />
}
function CardGaugeAll() {
    const navigate = useNavigate();

    const { sistemaSelecionado } = useSistemaSelecionado();
    const { data, isLoading, isFetching, error } = useQuery({
        queryKey: ['dispositivosSaude', sistemaSelecionado],
        queryFn: () => DispositivoRepo.getSaudeByClient(sistemaSelecionado!).catch((err: any) => {
            if (err.response?.status === 403) {
                Toast.error("Você não tem permissão para acessar essa página!");
            } else {
                Toast.error("Erro ao carregar saude de dispositivos");
            }
        }),
        enabled: !!sistemaSelecionado,
        staleTime: Infinity,
        refetchInterval: 120000,
        refetchIntervalInBackground: true,
    });

    if (isLoading || isFetching) {
        return <CircularProgress />
    }

    if (!data || error) {
        return <h1>Erro ao carregar dados do grupo</h1>
    }

    let content: any = {};

    Object.keys(data).forEach((dispositivo_id) => {
        let key = data[dispositivo_id].saude;
        if (!content[key]) {
            content[key] = 0;
        }
        content[key] += 1;
    });

    return <SetorCard title={"Todos"} content={content} onClick={() => { navigate(`/ativos`) }} />
}
function SetorCard(params: ISetorCard) {
    let data = Object.keys(params.content).map((key) => {
        return {
            label: key,
            value: params.content[key],
            color: key === 'Saudável' ? 'green' : key === 'Alerta' ? 'yellow' : 'red'
        };
    });

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="div">
                    {params.title}
                </Typography>
                <Box display="flex" justifyContent="center">
                    {Object.keys(params.content).length === 0 ? (<>
                        <Box display="flex" justifyContent="center" alignItems="center" height={200}>Sem dados</Box>
                    </>) : (
                        <PieChart
                            series={[{ data }]}
                            {...pieParams}
                            slotProps={{
                                legend: {
                                    position: {
                                        horizontal: 'middle', vertical: 'bottom'
                                    },
                                    direction: 'row'
                                }
                            }}
                        />
                    )}

                </Box>
            </CardContent>
            {params.title !== "Todos" ? (
                <CardActions>
                    <Button size="small" onClick={() => params.onClick()}>Visualizar ativos</Button>
                </CardActions>
            ) : (
                <Box flexGrow={1} minHeight={45} />
            )}
        </Card>
    );
}