import axios from "axios";
import Toast from "./Toast";
import * as jose from "jose";

let ambiente = 'hml';

let ambientes: { [key: string]: string } = {
    'dev': "http://127.0.0.1:5000/api/v1/",
    'prod': "https://2e5wvkau36re7toncu3r66g4sy0gyuzq.lambda-url.us-east-1.on.aws/",
    "hml": "https://c4gvutuvwkxdosch74vsciuslq0qudif.lambda-url.us-east-1.on.aws/api/v1/"
};
let api = axios.create({ baseURL: ambientes[ambiente] })

api.interceptors.request.use(async function (request: any) {
    if (window.sessionStorage.provib_telemetria) {
        let res = sessionStorage.getItem('provib_telemetria');
        let credentials = JSON.parse(res || '{}');
        let accessToken = credentials.AccessToken;


        const claims = jose.decodeJwt(accessToken);
        console.log(claims)


        const expiration = new Date((claims.exp ?? 0) * 1000);
        const now = new Date();

        now.setSeconds(now.getSeconds() - 300);
        
        if (now > expiration) {
            const refreshToken = credentials.RefreshToken;

            console.log("TOKEN NEXT TO EXPIRE, RENEWING");

            const response = await axios.post(`${ambientes[ambiente]}refresh-token`, { refresh_token: refreshToken });
            const newCredentials = response.data;

            // Update sessionStorage with new credentials
            if (!newCredentials.RefreshToken) {
                newCredentials.RefreshToken = refreshToken;
            }
            sessionStorage.setItem('provib_telemetria', JSON.stringify(newCredentials));

            accessToken = newCredentials.AccessToken;
        }

        request['headers']["Authorization"] = "Bearer " + accessToken;
    }
    return request;
});
api.interceptors.response.use(
    function (response) {
        // Simply return the response if there's no error
        return response;
    },
    async function (error) {
        if (error.response === undefined) {
            Toast.error("Erro de conexão com o servidor");
        } else if (error.response.status === 401) {
            console.log("REDIRECT TO LOGIN");
            // Redirect to login page for 401 errors
            if (!window.location.href.includes("/login")) {
                window.location.href = "/login";
            }
        } else {
            console.log("Erro desconhecido:", error.response.status);
            // Optionally handle other status codes or errors here
        }

        // Reject the promise with the error object
        return Promise.reject(error);
    }
);

async function refreshToken() {
    return new Promise((resolve, reject) => {
        let res = sessionStorage.getItem('provib_telemetria');
        console.log("---> refreshToken");
        try {
            let credentials = JSON.parse(res || '');
            let refreshToken = credentials.RefreshToken;

            api.post('/refresh-token', { "refresh_token": refreshToken }).then((response: any) => {
                let credentials = response.data;
                if (!credentials.RefreshToken) {
                    credentials.RefreshToken = refreshToken;
                }

                sessionStorage.setItem('provib_telemetria', JSON.stringify(credentials));
                resolve(credentials);
            }).catch((err: any) => {
                console.log("Erro ao executar RefreshToken");
                reject(err);
            });

        } catch (error) {
            console.log("Erro ao executar RefreshToken");
            reject(error);
        }
    });
}

export default api