import api from './Api';

interface IUsuario {
    id: string,
    email: string,
    phone_number: string,
    user_role: string,
    can_access_clients: string,
    permissions: string,
    sistema_owner: string
}

export type Usuario = IUsuario;

export default {
    getAll: async () => {
        return api.get(`/usuarios`);
    },
    create: async (email: string, phone_number: string, user_role:string, can_access_clients: string, permissions: string, sistema_owner: string) => {
        return api.post(`/usuarios`, {
            email,
            phone_number,
            user_role,
            can_access_clients,
            permissions,
            sistema_owner
        });
    },
    updateField: async (id: string, field: string, value: any) => {
        return api.put(`/usuarios`, {
            id,
            field,
            value
        });
    },
    update: async (usuario: IUsuario) => {
        return api.put(`/usuarios`, usuario);
    },
    whoami: async (): Promise<Usuario> => {
        return api.get(`/whoami`).then((response) => response.data);
    },
    delete: async (id: string) => {
        return api.delete(`/usuarios?id=${id}`);
    }
}