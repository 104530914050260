import React, { useEffect } from 'react';

import { useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, Select, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TabelaCrud from '../../components/TabelaCrud';
import DispositivoRepo, { Dispositivo } from '../../Services/DispositivoRepo';
import { ModalConfirmar, ModalCriarDispositivo } from '../../components/Modais';
import Toast from '../../Services/Toast';
import { useSistemaSelecionado } from '../../Contexts/BaseContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AlarmesRepo, { CadastroAlarme } from '../../Services/AlarmesRepo';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

export default function ListagemDispositivos() {
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // const [rows, setRows] = useState<Array<any>>([]);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [dispositivoIdDelete, setDispositivoIdDelete] = useState<string>("");

  const [openCriarDispositivo, setOpenCriarDispositivo] = useState(false);

  const [openCadastroAlarmes, setOpenCadastroAlarmes] = useState(false);

  const { sistemaSelecionado } = useSistemaSelecionado();

  const [selectedDispositivo, setSelectedDispositivo] = useState<Dispositivo | undefined>();

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: ['dispositivos', sistemaSelecionado],
    queryFn: () => DispositivoRepo.getByCliente(sistemaSelecionado ?? "").catch(() => { Toast.error("Erro ao carregar dispositivos") }),
    enabled: !!sistemaSelecionado,
  });

  const updateDispositivoMutation = useMutation({
    mutationFn: ({ id, field, value }: { id: string, field: string, value: any }) =>
      DispositivoRepo.updateField(id, field, value)
        .finally(() =>
          queryClient.invalidateQueries({ queryKey: ['dispositivos', sistemaSelecionado] })
        ),
    onSuccess: () => {
      Toast.success("Dispositivo atualizado com sucesso!");
      queryClient.invalidateQueries({ queryKey: ['grupos', sistemaSelecionado] });
    },
    onError: (err: any) => {
      if (err.response?.status === 403) {
        Toast.error("Você não tem permissão para editar dispositivos!");
      } else {
        Toast.error("Erro ao atualizar dispositivo");
      }
    }
  });

  const handleConfirmDispositivoDelete = () => {
    setLoading(true);
    DispositivoRepo.delete(dispositivoIdDelete).then(() => {
      setRefresh(!refresh);
      Toast.success("Dispositivo deletado com sucesso.");
    }).catch((err) => {

      Toast.error("Erro ao deletar dispositivo.");
    }).finally(() => {
      setOpenConfirmDelete(false);
      setLoading(false);
    });
  };

  const handleCriarDispositivo = (id: string, nome: string, tipo: string, local: string) => {
    setLoading(true);
    if (!sistemaSelecionado) {
      Toast.error("Selecione um sistema.");
      return;
    }
    DispositivoRepo.create(id, nome, tipo, local, sistemaSelecionado).then(() => {
      setRefresh(!refresh);
      Toast.success("Dispositivo criado com sucesso.");
    }).catch((err) => {
      if (err.response && err.response?.status === 403) {
        Toast.error("Sem permissão necessaria.");
      } else {
        Toast.error("Erro ao criar dispositivo.");
      }
    }).finally(() => {
      setOpenCriarDispositivo(false);
      setLoading(false);
    });
  };

  const handleOnCellEditCommit = (params: any) => {
    updateDispositivoMutation.mutate({ id: params.id, field: params.field, value: params.value });
  };

  let columns: GridColDef[] = [
    { field: "id", headerName: "Identificador", type: 'string', flex: 800, editable: false },
    { field: "nome", headerName: "Nome", type: 'string', flex: 800, editable: true },
    { field: "local", headerName: "Local", type: 'string', flex: 800, editable: true },
    {
      field: "tipo", headerName: "Tipo", type: 'string', flex: 800, editable: false,
      renderCell: (params: GridRenderCellParams<any, any>) => (
        <SelectTipo onCellEditCommit={handleOnCellEditCommit} params={params} />
      )
    },
    {
      field: 'alarmes', headerName: 'Alarmes', flex: 800,
      renderCell: (params: GridRenderCellParams<Dispositivo, any>) => (
        <Button
          sx={{ width: '-webkit-fill-available' }}
          variant="outlined"
          color="primary"
          onClick={() => { setSelectedDispositivo(params.row); setOpenCadastroAlarmes(true); }}
          style={{ width: '100%', height: '100%' }}
        >
          {params.row.alarmes?.length ?? 0} Alarmes
        </Button>
      ),
    },
    {
      field: 'delete', headerName: 'Deletar', flex: 800,
      renderCell: (params: GridRenderCellParams<any, any>) => (
        <IconButton
          sx={{ margin: "auto" }}
          onClick={() => { setDispositivoIdDelete(params.row.id); setOpenConfirmDelete(true) }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    }
  ];

  return (
    <>
      <TabelaCrud
        onRefreshClick={() => { queryClient.invalidateQueries({ queryKey: ['dispositivos', sistemaSelecionado] }) }}
        onAddClick={() => { setOpenCriarDispositivo(true) }}
        loading={isLoading || isFetching}
        error={error}
        rows={data ?? []}
        columns={columns}
        onCellEditCommit={handleOnCellEditCommit}
      />

      <ModalCriarDispositivo
        open={openCriarDispositivo}
        setOpen={setOpenCriarDispositivo}
        criarDispositivo={handleCriarDispositivo}
      />

      <ModalConfirmar
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        onConfirm={handleConfirmDispositivoDelete}
        title={'Tem certeza que deseja deletar o dispositivo?'}
        message={'Confirmar exclusão permanente de dispositivo.'}
      />

      <DialogAlarmesSelection
        open={openCadastroAlarmes}
        setOpen={setOpenCadastroAlarmes}
        selectedDispositivo={selectedDispositivo}
        handleClose={() => setSelectedDispositivo(undefined)}
        handleSave={(alarmes, dispositivoId) => {
          updateDispositivoMutation.mutate({ id: dispositivoId ?? "", field: 'alarmes', value: alarmes })
        }}
      />
    </>
  );
}

interface IPropsAlarmDialog {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedDispositivo: Dispositivo | undefined;
  handleClose: () => void;
  handleSave: (alarmes: string[], dispositivoId: string | undefined) => void;
}
function DialogAlarmesSelection(props: IPropsAlarmDialog) {
  const { sistemaSelecionado } = useSistemaSelecionado();
  const [alarmes, setAlarmes] = useState<{ nome: string, id: string }[]>([]);

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: ['cadastroAlarmes', sistemaSelecionado],
    queryFn: () => AlarmesRepo.getAllByCliente(sistemaSelecionado ?? "").catch(() => {
      Toast.error("Erro ao carregar dispositivos");
    }),
    enabled: !!sistemaSelecionado,
  });

  useEffect(() => {
    if (props.selectedDispositivo) {
      let defaultCheckedAlarmesIds: string[] = props.selectedDispositivo.alarmes ?? [];
      setAlarmes(data?.filter((alarme) => defaultCheckedAlarmesIds.includes(alarme.id)) ?? []);
    }
    console.log(props.selectedDispositivo);
  }, [props.selectedDispositivo]);

  if (isLoading || isFetching) return <p>Carregando...</p>;
  if (error) return <p>Erro ao carregar alarmes</p>;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <DialogTitle id="alert-dialog-title">Alarmes</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Escolha os alarmes para o dispositivo
        </DialogContentText>

        <Autocomplete
          sx={{ mt: 2 }}
          multiple
          id="tags-outlined"
          options={data?.map((alarme) => ({ nome: alarme.nome, id: alarme.id })) ?? []}
          getOptionLabel={(option) => option.nome}
          getOptionKey={(option) => option.id}
          value={alarmes}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            setAlarmes(newValue);
          }}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => {
            const { ...optionProps } = props;
            return (
              <li {...optionProps}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.nome}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Alarmes" placeholder="Alarmes" />
          )}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => { props.handleSave(alarmes.map((alarme) => alarme.id), props.selectedDispositivo?.id); props.handleClose(); props.setOpen(false); }} color="primary" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>

  );
}

function SelectTipo(props: { params: GridRenderCellParams<any, any>, onCellEditCommit: Function }) {
  const [enabled, setEnabled] = React.useState(false);

  return (
    <Select
      sx={{ width: '-webkit-fill-available' }}
      labelId="select-label"
      id="select"
      label="Tipo"
      disabled={!enabled}
      value={props.params.row.tipo}
      onDoubleClick={() => setEnabled(true)}
      onChange={(event) => {
        const newValue = event.target.value;
        setEnabled(false);
        props.onCellEditCommit({ id: props.params.id, field: 'tipo', value: newValue });
      }}
    >
      <MenuItem value={"Lora - Vibração"}>Lora - Vibração</MenuItem>
      <MenuItem value={"BLE - Vibração"}>BLE - Vibração</MenuItem>
    </Select>
  );
}