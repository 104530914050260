import api from './Api';

const Repository = {
    StatusConexao: async (client_id: string) => {
        return api.get(`/status_geral?client_id=${client_id}`);
    },
    LatestGlobalData: async (client_id: string): Promise<GlobalData[]> => {
        return api.get(`/latest_global_data?client_id=${client_id}`).then((response) => response.data).then((data: GlobalData[]) => {
            data.map((d) => {
                d.ts = new Date(parseInt(d.raw_ts) * 1000).toLocaleString("pt-BR");
                return d;
            });
            return data;
        });
    },
    LatestGlobalDataByDispositivo: async (dispositivo_id: string): Promise<GlobalData> => {
        return api.get(`/latest_global_data?dispositivo_id=${dispositivo_id}`).then((response) => response.data)
            .then((data: GlobalData) => {
                data.ts = new Date(parseInt(data.raw_ts) * 1000).toLocaleString("pt-BR")
                return data;
            });
    },
    ListGlobalData: async (dispositivo_id: string, from_ts: number, to_ts: number): Promise<GlobalData[]> => {
        return api.get(`/list_global_data?dispositivo_id=${dispositivo_id}&from_ts=${from_ts}&to_ts=${to_ts}`).then((response) => response.data)
            .then((data: GlobalData[]) => {
                data.map((d) => {
                    d.ts = new Date(parseInt(d.raw_ts) * 1000).toLocaleString("pt-BR")
                    return d
                });
                return data;
            });
    },
    ListGlobalDataByDay: async (dispositivo_id: string, dates: string): Promise<GlobalData[]> => {
        return api.get(`/list_global_data_by_day?dispositivo_id=${dispositivo_id}&dates=${dates}`).then((response) => response.data)
            .then((data: GlobalData[]) => {
                data.map((d) => {
                    d.ts = new Date(parseInt(d.raw_ts) * 1000).toLocaleString("pt-BR")
                    return d
                });
                return data;
            });
    },
    LatestGlobalDataByGrupoDispositivo: async (grupoDispositivoId: string): Promise<GlobalData[]> => {
        return api.get(`/latest_global_data?grupo_id=${grupoDispositivoId}`).then((response) => response.data)
            .then((data: GlobalData[]) => {
                data.map((d) => {
                    d.ts = new Date(parseInt(d.raw_ts) * 1000).toLocaleString("pt-BR")
                    return d
                })
                return data
            });
    }
}
export default Repository;

interface StatusConexao {
    Atrasado: number;
    Offline: number;
    Online: number;
}

interface GlobalData {
    "ts": string,
    "raw_ts": string,
    "temp": string,
    "Bateria": number,
    "dispositivo_id": string,
    "sistema_id": string,
    "tag": string,
    "apelido": string,
    "Horizontal(g)": number,
    "Vertical(g)": number,
    "Radial(g)": number,
    "Axial(g)": number,
    "Horizontal(mg)": number,
    "Vertical(mg)": number,
    "Radial(mg)": number,
    "Axial(mg)": number,
    "Horizontal(mm/s)": number,
    "Vertical(mm/s)": number,
    "Radial(mm/s)": number,
    "Axial(mm/s)": number,
    "Estado": string,
    "Angulo": number,
    "Temp(C)": string
}
export type { StatusConexao, GlobalData };