export const localeText = {
    toolbarColumns: 'Colunas',

    columnsPanelHideAllButton: 'Esconder Tudo',
    columnsPanelShowAllButton: 'Mostrar Tudo',
    columnsPanelTextFieldPlaceholder: 'Título da Coluna',
    columnsPanelTextFieldLabel: 'Título da Coluna',

    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Filtros',
    toolbarFiltersTooltipHide: 'Esconder Filtros',
    toolbarFiltersTooltipShow: 'Mostrar Filtros',
    toolbarFiltersTooltipActive: (count: number) => `${count} filtro(s) ativo(s)`,
    toolbarQuickFilterPlaceholder: 'Filtro Rápido',

    toolbarDensity: 'Densidade',
    toolbarDensityLabel: 'Densidade',
    toolbarDensityCompact: 'Compacto',
    toolbarDensityStandard: 'Padrão',
    toolbarDensityComfortable: 'Confortável',

    toolbarColumnsLabel: 'Selecionar Colunas',
    toolbarExport: 'Exportar',
    toolbarExportCSV: 'Exportar como CSV',
    toolbarExportPrint: 'Imprimir',

    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Esconder',
    columnMenuShowColumns: 'Mostrar Colunas',
    columnMenuUnsort: 'Desfazer Ordenação',
    columnMenuSortAsc: 'Ordenar Crescente',
    columnMenuSortDesc: 'Ordenar Decrescente',

    filterOperatorAfter: 'Depois',
    filterOperatorBefore: 'Antes',
    filterOperatorOnOrAfter: 'Em ou Depois',
    filterOperatorOnOrBefore: 'Em ou Antes',
    filterOperatorEquals: 'Igual',
    filterOperatorNot: 'Diferente',
    filterOperatorContains: 'Contém',

    filterOperatorStartsWith: 'Começa Com',
    filterOperatorEndsWith: 'Termina Com',
    filterOperatorIs: 'É',
    filterOperatorIsEmpty: 'Está Vazio',
    filterOperatorIsNotEmpty: 'Não Está Vazio',
    filterOperatorIsAnyOf: 'É Qualquer um de',

    footerRowSelected: (count: number) => `${count.toLocaleString()} linha(s) selecionada(s)`,
    footerTotalRows: 'Total de Linhas:',
    footerTotalVisibleRows: (visibleCount: number, totalCount: number) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()} linhas visíveis`,
    MuiTablePagination: {
        labelRowsPerPage: 'Linhas por página:',
        labelDisplayedRows: ({ from, to, count }: { from: number, to: number, count: number }) => `${from}-${to === -1 ? count : to} de ${count !== -1 ? `de ${count}` : ''}`,
    }
};