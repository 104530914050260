import React from "react";
import { BrowserRouter } from "react-router-dom";
import Rotas from "./routes";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SistemaSelecionadoProvider } from "./Contexts/BaseContext";

const queryClient = new QueryClient();

function App() {
  return (

    <SistemaSelecionadoProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Rotas />
        </BrowserRouter>
      </QueryClientProvider>
    </ SistemaSelecionadoProvider>
  );
}
export default App;
