import api from './Api';

interface Evento {
    'id': string,
    'dispositivo_id': string,
    'tag': string,
    'sistema_id': string,
    'ts': string,
    'raw_ts': number,
    'evento': string,
    'criticidade': number
}

export type { Evento };

const Repository = {
    GetLatestByClientId: async (client_id: string, limit: number) => {
        return api.get(`/eventos/latest?client_id=${client_id}&limit=${limit}`).then((response) => response.data)
            .then((data: Evento[]) => {
                data.map((d) => {
                    d.ts = new Date(d.raw_ts * 1000).toLocaleString("pt-BR");
                    return d;
                })
                return data;
            });
    }
}

export default Repository;