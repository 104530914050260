import React, { useState } from 'react';
import { ModalCadastroAlarme } from '../../components/Modais';
import TabelaCrud from '../../components/TabelaCrud';
import { GridColDef } from '@mui/x-data-grid';
import { useSistemaSelecionado } from '../../Contexts/BaseContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AlarmesRepo, { CadastroAlarme, Trigger } from '../../Services/AlarmesRepo';
import Toast from '../../Services/Toast';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Typography } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsIcon from '@mui/icons-material/Notifications';

export default function Alarmes() {
  const queryClient = useQueryClient();

  const { sistemaSelecionado } = useSistemaSelecionado();
  const [open, setOpen] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [selectedAlarm, setSelectedAlarm] = useState<CadastroAlarme | null>(null);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['alarmes', sistemaSelecionado],
    queryFn: () => AlarmesRepo.getAllByCliente(sistemaSelecionado!),
    enabled: !!sistemaSelecionado,
  });

  const createAlarmMutation = useMutation({
    mutationFn: (alarmData: CadastroAlarme) => AlarmesRepo.create(alarmData),
    onSuccess: () => {
      Toast.success("Alarme criado com sucesso!");
      queryClient.invalidateQueries({ queryKey: ['alarmes', sistemaSelecionado] });
    },
    onError: () => {
      Toast.error("Erro ao criar alarme");
    }
  });

  const updateAlarmMutation = useMutation({
    mutationFn: (updatedAlarme: CadastroAlarme) => AlarmesRepo.update(updatedAlarme),
    onSuccess: () => {
      Toast.success("Alarme atualizado com sucesso!");
      queryClient.invalidateQueries({ queryKey: ['alarmes', sistemaSelecionado] });
    },
    onError: (err: any) => {
      if (err.response?.status === 403) {
        Toast.error("Você não tem permissão para acessar essa página!");
      } else {
        Toast.error("Erro ao atualizar alarme");
      }
    }
  });

  const deleteAlarmMutation = useMutation({
    mutationFn: (id: string) => AlarmesRepo.delete(id),
    onSuccess: () => {
      Toast.success("Alarme deletado com sucesso!");
      queryClient.invalidateQueries({ queryKey: ['alarmes', sistemaSelecionado] });
    },
    onError: (err: any) => {
      if (err.response?.status === 403) {
        Toast.error("Você não tem permissão para acessar essa página!");
      } else {
        Toast.error("Erro ao deletar alarme");
      }
    }
  });

  const columns: GridColDef[] = [
    { field: 'nome', headerName: 'Nome', flex: 800, editable: false },
    { field: 'criticidade', headerName: 'Criticidade', flex: 800, editable: false },
    { field: 'descricao', headerName: 'Descrição', flex: 800, editable: false },
    {
      field: 'ativo', headerName: 'Ativo', flex: 800, editable: false,
      renderCell: (params) => (params.value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)
    },
    {
      field: 'triggers', headerName: 'Condições', flex: 800, editable: false,
      renderCell: (params) => {
        const content = getConditionText(params.row.triggers);
        let truncatedContent = content;
        if (truncatedContent.length > 20) {
          truncatedContent = truncatedContent.substring(0, 20) + '...';
        }
        return (
          <Tooltip title={<div>{content}</div>} placement="top" arrow>
            <div>{truncatedContent}</div>
          </Tooltip>
        );
      }
    },
    {
      field: "acoes", headerName: "Ações", flex: 800,
      renderCell: (params) => <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
        <IconButton onClick={() => {
          updateAlarmMutation.mutate({ ...params.row, ativo: !params.row.ativo });
        }}>
          {params.row.ativo ? <NotificationsOffIcon /> : <NotificationsIcon />}
        </IconButton>

        <IconButton onClick={() => {
          setSelectedAlarm(params.row as CadastroAlarme);
          setEditMode(true);
          setOpen(true);
        }}>
          <EditNoteIcon />
        </IconButton>
        <IconButton onClick={() => { setSelectedAlarm(params.row as CadastroAlarme); setOpenConfirmDelete(true); }}>
          <DeleteIcon />
        </IconButton>
      </Box>
    },
  ];
  
  function getConditionText(conditions: Trigger[]) {
    let text = '';
    conditions.forEach((condition, index) => {
      let logic = condition.logic == 'AND' ? 'E (&)' : 'OU (||)';
      if (index > 0) {
        text += '\n' + logic + '\n';
      }
      text += `${condition.conditions.map((c) => `${c.type} ${c.comparator} ${c.value}`).join(` ${logic} `)}`;

    });
    return text;
  }

  function getTruncatedConditionText(index: number, condition: any) {
    let text = '';
    if (index > 0) {
      text += condition.logic == 'AND' ? 'E (&)' : 'OU (||)' + '\n';
    }
    text += `${condition.type} ${condition.comparator} ${condition.value}` + '\n';
    if (text.length > 20) { // truncate if the text is longer than 20 characters
      text = text.substring(0, 20) + '...';
    }
    return text;
  }

  function handleSaveAlarm(alarmData: CadastroAlarme) {
    alarmData.sistema_id = sistemaSelecionado!;
    if (editMode) {
      updateAlarmMutation.mutate(alarmData);
      setEditMode(false);
      setSelectedAlarm(null);
    } else {
      createAlarmMutation.mutate(alarmData);
    }
  }

  return (
    <>
      <TabelaCrud
        onRefreshClick={() => queryClient.invalidateQueries({ queryKey: ['alarmes', sistemaSelecionado] })}
        columns={columns}
        rows={data || []}
        loading={isLoading || isFetching}
        onCellEditCommit={(params: { id: string, field: keyof CadastroAlarme, value: string }) => {
          let alarme: any | undefined = data?.find((alarme: CadastroAlarme) => alarme.id === params.id);
          if (alarme) {
            alarme[params.field] = params.value;
            updateAlarmMutation.mutate(alarme);
          }
        }}
        onAddClick={() => setOpen(true)}
      />
      <ModalCadastroAlarme
        open={open}
        handleClose={() => setOpen(false)}
        handleSave={handleSaveAlarm}
        editMode={editMode}
        selectedAlarm={selectedAlarm}
      />

      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deletar"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja deletar o alerme "{selectedAlarm?.nome}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDelete(false)}> Não </Button>
          <Button onClick={() => { deleteAlarmMutation.mutate(selectedAlarm?.id ?? ""); setOpenConfirmDelete(false); }} autoFocus> Sim </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
