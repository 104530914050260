
import React, { useState } from 'react';

import { Box, Button, IconButton, MenuItem, Select } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { localeText } from './localeText';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { RefreshOutlined } from '@mui/icons-material';
import BootstrapSelect from '../CustomInputs/BootstrapSelect';

export default function TabelaCrud({ loading, columns, rows, onCellEditCommit, onAddClick, onRefreshClick, error }: { loading: any, columns: GridColDef[], rows: any, onCellEditCommit?: any, onAddClick?: React.MouseEventHandler<HTMLButtonElement>, onRefreshClick?: any, error?: any}) {
    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ borderBottom: '1px solid #99B3E6' }} >
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport /><Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {onAddClick ? (<>

                        <IconButton onClick={onAddClick} color='primary'><AddCircleOutlineIcon /></IconButton>

                    </>) : null}

                    {onRefreshClick ? (<>
                        <IconButton onClick={onRefreshClick} color='primary'><RefreshOutlined /></IconButton>
                    </>) : null}

                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <Box key={"TabelaCrud"} alignContent={'center'} alignSelf={'center'} sx={{ margin: "auto", minWidth: "80vh", width: "95%", height: '100%', overflow: 'hidden' }}>
            <DataGrid
                key={"DataGrid"}
                components={{ Toolbar: CustomToolbar }}
                error={error ?? undefined}
                columns={columns}
                rows={rows}
                localeText={localeText}
                rowsPerPageOptions={[5, 10, 20, 100]}
                onCellEditCommit={onCellEditCommit}
                loading={loading}
                disableSelectionOnClick
                sx={{
                    margin: "10px",
                    '& .MuiDataGrid-cell--withRenderer': {
                        padding: 0,
                    },
                }}
            />
        </Box>
    );
}

