import Swal from "sweetalert2";

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast: { addEventListener: (arg0: string, arg1: any) => void; }) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export default {
    success: (message: string) => {
        Toast.fire({
            icon: 'success',
            title: message
        })
    },
    error: (message: string) => {
        Toast.fire({
            icon: 'error',
            title: message
        })
    },
    warning: (message: string) => {
        Toast.fire({
            icon: 'warning',
            title: message
        })
    },
    info: (message: string) => {
        Toast.fire({
            icon: 'info',
            title: message
        })
    }
}