import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Usuario } from "../../Services/UsuariosRepo";
import { useLoggedUser } from "../../Contexts/LoggedUserContext";

export default function ModalCriarUsuario(props: { open: boolean, setOpen: Function, criarUsuario: (usuario: Usuario) => void }) {
  const [email, setEmail] = React.useState<string>("");
  const [phone_number, setPhoneNumber] = React.useState<string>("+55");
  const [user_role, setUserRole] = React.useState<string>("");

  const { user } = useLoggedUser();

  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Criar Usuario</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para criar um novo usuario, preencha os campos abaixo.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="E-mail"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          error={email.length < 1}
          fullWidth
        />
        <TextField
          margin="dense"
          id="telefone"
          label="Telefone"
          type="text"
          value={phone_number}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
          error={phone_number.length < 10}
          fullWidth

        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="select-user_role">Cargo</InputLabel>
          <Select
            labelId="select-user_role"
            id="user_role"
            label="Cargo"
            value={user_role}
            onChange={(e) => setUserRole(e.target.value)}
            required
            error={user_role.length < 1}
          >
            {["sup_admin"].includes(user?.user_role ?? "") ? <MenuItem value={"sup_admin"}>Super Admin</MenuItem> : null}
            {["admin", "sup_admin"].includes(user?.user_role ?? "") ? <MenuItem value={"admin"}>Admin</MenuItem> : null}
            <MenuItem value={"viewer"}>Analista</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} color="primary">
          Cancelar
        </Button>
        <Button color="primary" autoFocus onClick={() => {
          let user: Usuario = {
            id: "",
            email: email,
            phone_number: phone_number,
            user_role: user_role,
            can_access_clients: "",
            permissions: "",
            sistema_owner: ""
          };
          props.criarUsuario(user);
        }
        }>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}