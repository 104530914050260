import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

export default function ModalCriarDispositivo(props: { open: boolean, setOpen: Function, criarDispositivo: Function}) {
    const [id, setId] = React.useState("");
    const [name, setName] = React.useState("");
    const [tipo, setTipo] = React.useState("");
    const [local, setLocal] = React.useState("");

    return (
        <Dialog
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Criar Dispositivo</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Para criar um novo dispositivo, preencha os campos abaixo.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="id"
                    label="Identificador"
                    type="text"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    required
                    error={id.length < 1}
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nome"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    error={name.length < 1}
                    fullWidth
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel id="select-label">Tipo</InputLabel>
                    <Select
                        labelId="select-label"
                        id="select"
                        label="Tipo"
                        value={tipo}
                        onChange={(e) => setTipo(e.target.value)}
                        required
                        error={tipo.length < 1}
                    >
                        <MenuItem value={"Lora - Vibração"}>Lora - Vibração</MenuItem>
                        <MenuItem value={"BLE - Vibração"}>BLE - Vibração</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    autoFocus
                    margin="dense"
                    id="local"
                    label="Local"
                    type="text"
                    value={local}
                    onChange={(e) => setLocal(e.target.value)}
                    required
                    error={local.length < 1}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => { props.criarDispositivo(id, name, tipo, local); }} color="primary" autoFocus>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}