import * as React from "react";
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, FormControlLabel, FormGroup, IconButton, TextField
} from "@mui/material";
import TabelaCrud from '../../components/TabelaCrud';
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useState } from "react";
import DispositivoRepo, { Dispositivo } from "../../Services/DispositivoRepo";
import DeleteIcon from '@mui/icons-material/Delete';
import GrupoDispositivosRepo, { GrupoDispositivo } from "../../Services/GrupoDispositivosRepo";
import Toast from "../../Services/Toast";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { MuiFileInput } from 'mui-file-input'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useSistemaSelecionado } from "../../Contexts/BaseContext";
import { useQuery, useMutation, QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function ListagemGruposDispositivos() {
  const [openDispositivoSelect, setOpenDispositivoSelect] = useState(false);
  const [openCreateGroup, setOpenCreateGroup] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openImageSelector, setOpenImageSelector] = useState(false);
  const [modalGroupName, setModalGroupName] = useState("");
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [changesGrupo, setChangesGrupo] = useState<any>([]);
  const [imageFileValue, setImageFileValue] = useState<File | null>(null);
  const { sistemaSelecionado } = useSistemaSelecionado();

  const { data: dispositivos = [], isLoading: isLoadingDispositivos, isFetching: isFetchingDispositivos } = useQuery({
    queryKey: ['dispositivos', sistemaSelecionado],
    queryFn: () => DispositivoRepo.getByCliente(sistemaSelecionado || "").catch(() => { Toast.error("Erro ao carregar dispositivos") }),
    enabled: !!sistemaSelecionado,
  });
  
  const { data: rows = [], isLoading: isLoadingGrupos, isFetching: isFetchingGrupos } = useQuery({
    queryKey: ['grupos', sistemaSelecionado],
    queryFn: () => GrupoDispositivosRepo.getAll(sistemaSelecionado || "").catch((err: any) => {
      if (err.response?.status === 403) {
        Toast.error("Você não tem permissão para acessar essa página!");
      } else {
        Toast.error("Erro ao carregar grupos de dispositivos");
      }
    }),
    enabled: !!sistemaSelecionado,
  });

  const updateGroupMutation = useMutation({
    mutationFn: (newGrupo: GrupoDispositivo) => GrupoDispositivosRepo.update(newGrupo),
    onSuccess: () => {
      Toast.success("Grupo atualizado com sucesso!");
      queryClient.invalidateQueries({ queryKey: ['grupos', sistemaSelecionado] });
    },
    onError: (err: any) => {
      if (err.response?.status === 403) {
        Toast.error("Você não tem permissão para acessar essa página!");
      } else {
        Toast.error("Erro ao atualizar grupo");
      }
    }
  });

  const createGroupMutation = useMutation({
    mutationFn: (groupData: { sistemaId: string, groupName: string }) => GrupoDispositivosRepo.create(groupData.sistemaId, groupData.groupName, []),
    onSuccess: () => {
      Toast.success("Grupo criado com sucesso!");
      queryClient.invalidateQueries({ queryKey: ['grupos', sistemaSelecionado] });
    },
    onError: () => {
      Toast.error("Erro ao criar grupo");
    }
  });

  const deleteGroupMutation = useMutation({
    mutationFn: (id: string) => GrupoDispositivosRepo.delete(id),
    onSuccess: () => {
      Toast.success("Grupo deletado com sucesso!");
      queryClient.invalidateQueries({ queryKey: ['grupos', sistemaSelecionado] });
      setOpenConfirmDelete(false);
    },
    onError: () => {
      Toast.error("Erro ao deletar grupo");
    }
  });

  const uploadImageMutation = useMutation({
    mutationFn: ({ row, file }: { row: any, file: File }) => GrupoDispositivosRepo.uploadImage(row, file),
    onSuccess: () => {
      Toast.success("Imagem atualizada com sucesso!");
      queryClient.invalidateQueries({ queryKey: ['grupos', sistemaSelecionado] });
    },
    onError: () => {
      Toast.error("Erro ao atualizar imagem");
    }
  });

  const handleClickOpenDispositivoSelect = (row: any) => {
    setSelectedRow(row);
    setOpenDispositivoSelect(true);
  };

  const handleCloseDispositivoSelect = () => {
    if (changesGrupo.length === 0 || changesGrupo[selectedRow?.id]?.changes.length === 0) {
      setOpenDispositivoSelect(false);
      return;
    }
    for (let grupo_id in changesGrupo) {
      let grupos = rows.filter((row: any) => row.id === grupo_id);
      if (grupos.length === 0)
        continue;
      let dispositivosSet = new Set(grupos[0].dispositivos);
      changesGrupo[grupo_id].changes.forEach((change: any) => {
        if (change.action === "add") {
          dispositivosSet.add(change.id);
        } else {
          dispositivosSet.delete(change.id);
        }
      });
      let newGrupo = { ...grupos[0], dispositivos: Array.from(dispositivosSet) };
      updateGroupMutation.mutate(newGrupo);
    }
    setOpenDispositivoSelect(false);
  };

  const handleCloseCreateGroup = (criarGrupo: boolean) => {
    if (criarGrupo) {
      createGroupMutation.mutate({ sistemaId: sistemaSelecionado || "", groupName: modalGroupName });
    }
    setOpenCreateGroup(false);
  };

  const [currentSelection, setCurrentSelection] = useState<{ [key: string]: boolean }>(
    dispositivos.reduce((acc: any, dispositivo: Dispositivo) => ({ ...acc, [dispositivo.id]: false }), {})
  );

  let columns: GridColDef[] = [
    { field: "id", headerName: "Identificador", type: 'string', flex: 800, editable: false },
    { field: "nome", headerName: "Nome", type: 'string', flex: 800, editable: true },
    {
      field: 'dispositivos', headerName: 'Dispositivos', flex: 800,
      renderCell: (params: GridRenderCellParams<any, any>) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleClickOpenDispositivoSelect(params.row)}
          style={{ width: '100%', height: '100%' }}
        >
          {params.row.dispositivos?.length} Dispositivos
        </Button>
      ),
    },
    {
      field: 'imagem', headerName: 'Foto', flex: 800,
      renderCell: (params: GridRenderCellParams<any, any>) => (
        <IconButton
          sx={{ margin: "auto" }}
          onClick={() => { setSelectedRow(params.row); setOpenImageSelector(true) }}
        >
          <UploadFileIcon />
        </IconButton>
      ),
    },
    {
      field: 'delete', headerName: 'Deletar', flex: 800,
      renderCell: (params: GridRenderCellParams<any, any>) => (
        <IconButton
          sx={{ margin: "auto" }}
          onClick={() => { setOpenConfirmDelete(true); setSelectedRow(params.row) }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    }
  ];

  const handleSelectionChange = (event: any) => {
    setCurrentSelection({ ...currentSelection, [event.target.name]: event.target.checked });

    let dispositivo_id: string = event.target.name;
    let selected: boolean = event.target.checked;
    let grupo_id: string = selectedRow?.id;

    if (!(grupo_id in changesGrupo)) {
      setChangesGrupo({ ...changesGrupo, [grupo_id]: { changes: [] } });
    }

    setChangesGrupo((prevChangesGrupo: any) => {
      let changes = prevChangesGrupo[grupo_id]?.changes || [];
      return {
        ...prevChangesGrupo,
        [grupo_id]: {
          ...prevChangesGrupo[grupo_id],
          changes: [...changes, { id: dispositivo_id, action: selected ? "add" : "remove" }]
        }
      };
    });
  };

  function uploadImage(file: File | null) {
    if (!file) return;
    uploadImageMutation.mutate({ row: selectedRow, file });
    setImageFileValue(null);
  }

  return (
    <>
      <TabelaCrud
        onRefreshClick={() => queryClient.invalidateQueries({ queryKey: ['grupos', sistemaSelecionado] })}
        onAddClick={() => setOpenCreateGroup(true)}
        loading={isLoadingDispositivos || isFetchingDispositivos || isLoadingGrupos || isFetchingGrupos}
        rows={rows}
        columns={columns}
        onCellEditCommit={(params: any) => {
          let grupos = rows.filter((row: any) => row.id === params.id);
          if (grupos.length === 0) return;
          let newGrupo: any = { ...grupos[0] };
          newGrupo[params.field] = params.value;
          updateGroupMutation.mutate(newGrupo);
        }}
      />

      <Dialog open={openDispositivoSelect} onClose={handleCloseDispositivoSelect} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Select Departments</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              {dispositivos.map((dispositivo: Dispositivo) => (
                <FormControlLabel
                  key={dispositivo.id}
                  control={<Checkbox defaultChecked={selectedRow?.dispositivos.includes(dispositivo.id)} onChange={handleSelectionChange} name={dispositivo.id} />}
                  label={dispositivo.nome}
                />
              ))}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDispositivoSelect} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCreateGroup} onClose={() => handleCloseCreateGroup(false)}>
        <DialogTitle>Criar um novo grupo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para criar um novo grupo de dispositivos, preencha os campos abaixo.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome do grupo"
            type="text"
            fullWidth
            value={modalGroupName}
            onChange={(e) => setModalGroupName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseCreateGroup(false)}>Cancelar</Button>
          <Button onClick={() => handleCloseCreateGroup(true)}>Salvar</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deletar"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja deletar o grupo "{selectedRow?.nome}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDelete(false)}> Não </Button>
          <Button onClick={() => deleteGroupMutation.mutate(selectedRow?.id)} autoFocus> Sim </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openImageSelector}
        onClose={() => setOpenImageSelector(false)}
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      >
        <DialogTitle id="alert-dialog-title">
          Selecione uma imagem para o grupo {selectedRow?.nome}:
        </DialogTitle>
        <img
          style={{ maxHeight: "500px", maxWidth: "500px", margin: "auto" }}
          src={`https://painel-proativa.s3.amazonaws.com/STATIC/${selectedRow?.sistema_id}/${selectedRow?.id}`}
          alt="image"
        />
        <MuiFileInput
          size="small"
          variant="outlined"
          title={"Escolha uma imagem"}
          placeholder="Escolha uma imagem"
          margin="dense"
          sx={{ margin: "10px 10px 10px" }}
          InputProps={{
            inputProps: {
              accept: 'image/png, image/jpeg', // This line restricts the file input to .png, .jpg, and .jpeg files
            },
            startAdornment: <AttachFileIcon />
          }}
          value={imageFileValue}
          onChange={(file: File | null) => {
            setImageFileValue(file);
            uploadImage(file as File);
          }}
        />
      </Dialog>
    </>
  );
}

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ListagemGruposDispositivos />
    </QueryClientProvider>
  );
}
