import React, { useEffect, useState } from 'react';
import {
  Modal, Box, Typography, Button, TextField, Select, MenuItem,
  FormControl, InputLabel, Grid, IconButton, Divider, Checkbox, ListItemText
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import Toast from '../../Services/Toast';

interface Condition {
  type: string;
  comparator: string;
  value: string;
}

interface ConditionGroup {
  logic: string;
  conditions: Condition[];
}

const initialCondition: Condition = { type: 'Horizontal(g)', comparator: '>', value: '' };
const initialGroup: ConditionGroup = { logic: 'AND', conditions: [initialCondition] };
const allGroupsOption = '*';

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleSave: (data: any) => void;
  editMode?: boolean;
  selectedAlarm?: any;
}
export default function ModalCadastroAlarme({ open, handleClose, handleSave, editMode, selectedAlarm }: IProps) {
  const [name, setName] = useState(selectedAlarm?.nome || '');
  const [description, setDescription] = useState(selectedAlarm?.descricao || '');
  const [criticality, setCriticality] = useState(selectedAlarm?.criticidade || 'Baixa');
  const [conditionGroups, setConditionGroups] = useState<ConditionGroup[]>([initialGroup]);

  useEffect(() => {
    if (selectedAlarm) {
      setName(selectedAlarm.nome);
      setDescription(selectedAlarm.descricao);
      setCriticality(selectedAlarm.criticidade);
      setConditionGroups(selectedAlarm.triggers);
    }
  }, [selectedAlarm]);
  const handleAddGroup = () => {
    setConditionGroups([...conditionGroups, { ...initialGroup }]);
  };

  const handleRemoveGroup = (groupIndex: number) => {
    const newGroups = conditionGroups.filter((_, i) => i !== groupIndex);
    setConditionGroups(newGroups);
  };

  const handleAddCondition = (groupIndex: number) => {
    const newGroups = conditionGroups.map((group, i) =>
      i === groupIndex ? { ...group, conditions: [...group.conditions, { ...initialCondition }] } : group
    );
    setConditionGroups(newGroups);
  };

  const handleRemoveCondition = (groupIndex: number, conditionIndex: number) => {
    const newGroups = conditionGroups.map((group, i) =>
      i === groupIndex
        ? { ...group, conditions: group.conditions.filter((_, j) => j !== conditionIndex) }
        : group
    );
    setConditionGroups(newGroups);
  };

  const handleConditionChange = (groupIndex: number, conditionIndex: number, key: keyof Condition, value: string) => {
    const newGroups = conditionGroups.map((group, i) =>
      i === groupIndex
        ? {
          ...group,
          conditions: group.conditions.map((condition, j) =>
            j === conditionIndex ? { ...condition, [key]: value } : condition
          )
        }
        : group
    );
    setConditionGroups(newGroups);
  };

  const handleLogicChange = (groupIndex: number, event: any) => {
    const newGroups = conditionGroups.map((group, i) =>
      i === groupIndex ? { ...group, logic: event.target.value as string } : group
    );
    setConditionGroups(newGroups);
  };

  const handleSubmit = () => {
    if (!name || !description || !criticality) {
      Toast.error("Por favor, preencha todos os campos antes de salvar.");
      return;
    }

    if (conditionGroups.length === 0 || conditionGroups.some(group => group.conditions.length === 0 || group.conditions.some(condition => !condition.value))) {
      Toast.error("Por favor, adicione pelo menos uma condição com valores antes de salvar.");
      return;
    }

    const alarmData: any = {
      nome: name,
      descricao: description,
      criticidade: criticality,
      ativo: true,
      triggers: conditionGroups
    };
    if (editMode) {
      alarmData.id = selectedAlarm.id;
    }
    handleSave(alarmData);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{
        width: "600px",
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: 1,
        maxHeight: '80vh',
        overflow: 'auto'
      }}>
        <Typography variant="h6" component="h2">
          {editMode ? "Editar Alarme" : "Cadastro de Alarme"}
        </Typography>
        <TextField
          label="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          error={!name || name.length < 3}
          sx={{ mt: 2 }}
        />
        <TextField
          label="Descrição"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          error={!description || description.length < 3}
          sx={{ mt: 2 }}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Criticidade</InputLabel>
          <Select
            label={"Criticidade"}
            value={criticality}
            onChange={(e) => setCriticality(e.target.value as string)}
            error={!criticality}
          >
            <MenuItem value="Baixa">Baixa</MenuItem>
            <MenuItem value="Média">Média</MenuItem>
            <MenuItem value="Alta">Alta</MenuItem>
          </Select>
        </FormControl>
        <Divider sx={{ my: 2 }} />
        {conditionGroups.map((group, groupIndex) => (
          <Box key={groupIndex} sx={{ mt: 2, border: '1px solid #ccc', borderRadius: 1, p: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Operador Lógico</InputLabel>
              <Select label={"Operador Lógico"} value={group.logic} onChange={(event) => handleLogicChange(groupIndex, event)}>
                <MenuItem value="AND">E (&)</MenuItem>
                <MenuItem value="OR">OU (||)</MenuItem>
              </Select>
            </FormControl>
            {group.conditions.map((condition, conditionIndex) => (
              <React.Fragment key={conditionIndex}>
                {conditionIndex > 0 && (
                  <Typography align="center" variant="body2" sx={{ mb: 2 }}>
                    {group.logic == 'AND' ? 'E (&)' : 'OU (||)'}
                  </Typography>
                )}
                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Eixo</InputLabel>
                      <Select
                        label={"Eixo"}
                        value={condition.type}
                        error={!condition.type}
                        onChange={(e) => handleConditionChange(groupIndex, conditionIndex, 'type', e.target.value as string)}
                      >
                        <MenuItem value="Horizontal(g)">Horizontal(g)</MenuItem>
                        <MenuItem value="Vertical(g)">Vertical(g)</MenuItem>
                        <MenuItem value="Axial(g)">Axial(g)</MenuItem>

                        <MenuItem value="Horizontal(mm/s)">Horizontal(mm/s)</MenuItem>
                        <MenuItem value="Vertical(mm/s)">Vertical(mm/s)</MenuItem>
                        <MenuItem value="Axial(mm/s)">Axial(mm/s)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel>Comparador</InputLabel>
                      <Select
                        error={!condition.comparator}
                        label={"Comparador"}
                        value={condition.comparator}
                        onChange={(e) => handleConditionChange(groupIndex, conditionIndex, 'comparator', e.target.value as string)}
                      >
                        <MenuItem value=">">{'>'}</MenuItem>
                        <MenuItem value="<">{'<'}</MenuItem>
                        <MenuItem value=">=">{'>='}</MenuItem>
                        <MenuItem value="<=">{'<='}</MenuItem>
                        <MenuItem value="==">{'=='}</MenuItem>
                        <MenuItem value="!=">{'!='}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      error={!condition.value}
                      label="Valor"
                      type="number"
                      value={condition.value}
                      onChange={(e) => handleConditionChange(groupIndex, conditionIndex, 'value', e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => handleRemoveCondition(groupIndex, conditionIndex)} color="error">
                      <RemoveCircleOutline />
                    </IconButton>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddCircleOutline />}
              onClick={() => handleAddCondition(groupIndex)}
              sx={{ mb: 2 }}
            >
              Adicionar condição
            </Button>
            <Box
              flexGrow={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                startIcon={<RemoveCircleOutline />}
                onClick={() => handleRemoveGroup(groupIndex)}
              >
                Remover
              </Button>
            </Box>
          </Box>
        ))}
        <Button
          variant="outlined"
          startIcon={<AddCircleOutline />}
          onClick={handleAddGroup}
          sx={{ mt: 2, mr: 2 }}
        >
          Adicionar Gatilho
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
          Salvar
        </Button>
      </Box>
    </Modal>
  );
}
