import React, { createContext, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import UsuariosRepo, { Usuario } from '../Services/UsuariosRepo';
import { Outlet } from 'react-router-dom';
import { useSistemaSelecionado } from './BaseContext';

const LoggedUserContext = createContext<{ user_id: string | undefined; user: Usuario | undefined; isPending: boolean }>({
  user_id: undefined,
  user: undefined,
  isPending: true,
});

export const useLoggedUser = () => useContext(LoggedUserContext);

export const LoggedUserProvider = ({ children }: { children?: React.ReactNode }) => {

  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ['LoggedUser'],
    queryFn: () => UsuariosRepo.whoami(),
  })

  let user_id = data?.id;
  let user = data;

  let defaultSistema = window.localStorage.getItem('last_sistema_id') || null;
  const { setSistemaSelecionado } = useSistemaSelecionado();

  if (user?.can_access_clients) {
    if (user?.can_access_clients?.split(',')?.length > 0 && !defaultSistema) {
      window.localStorage.setItem('last_sistema_id', user?.can_access_clients?.split(',')[0]);
      setSistemaSelecionado(user?.can_access_clients?.split(',')[0]);
    }
  }
  return (
    <LoggedUserContext.Provider value={{ user_id, user, isPending: isPending || isFetching }}>
      {children}
      <Outlet />
    </LoggedUserContext.Provider>
  );
};

