import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import api from '../../Services/Api';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const theme = createTheme();

export default function SignInSide() {  
  const emailElement = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast: { addEventListener: (arg0: string, arg1: any) => void; }) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let credentials: object = { email: data.get('email'), password: data.get('password'), }
    
    api.post('/login', credentials)
      .then((response: any) => {
        let res = response.data;
        if ("AccessToken" in res) {
          sessionStorage.setItem('provib_telemetria', JSON.stringify(res));
          Toast.fire({
            icon: 'success',
            title: 'Login feito com sucesso.'
          })
          setTimeout(function () {
            window.location.pathname = "/home";
          }, 300);
        }
      })
      .catch((err: any) => {
        if (err.request.status === 400) {
          Toast.fire({
            icon: 'error',
            title: 'Preencha todos os campos obrigatórios.'
          })
        } else if (err.request.status === 401 || err.request.status === 500) {
          Toast.fire({
            icon: 'error',
            title: 'Usuário/senha inválidos.'
          })
        } else if (err.request.status === 402) {
          setTimeout(function () {
            window.location.pathname = "/primeiro-acesso";
          }, 100);
        }

      }
      )
  };

  const startForgotPassword = () => {
    let emailInp = emailElement.current;
    if(emailInp) window.sessionStorage.email=emailInp.getElementsByTagName('input')[0].value;

    api.post("/forgot-password", { email: window.sessionStorage.email }).then((result: any) => {
      Toast.fire({
        icon: 'success',
        title: 'Código de confirmação enviado.'
      })
      setTimeout(function () {
        navigate("/forgot-password");
      }, 300);
    }).catch((err: any) => {
      Toast.fire({
        icon: 'error',
        title: 'Erro ao enviar código.'
      })
    });


  }
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(/Img/provib.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => t.palette.grey[50],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Bem vindo ao sistema
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                ref={emailElement}
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => { window.sessionStorage.email = e.target.value; }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Entrar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link onClick={startForgotPassword} variant="body2">
                    Esqueceu sua senha?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}