import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import api from '../../Services/Api';
import Swal from 'sweetalert2';

const theme = createTheme();


export default function SiConfirmarEmailPagegnUp() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast: { addEventListener: (arg0: string, arg1: any) => void; }) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

    let email:string = window.sessionStorage.email;
    let code:string = "";

    function reenviarCodigo(){
        
        if (email && email.trim()) {
            api.post('/resend-confirmation-code', { "email": email })
            .then((response: any) => {
                Toast.fire({
                    icon: 'success',
                    title: "Codigo reenviado."
                })

            })
            .catch((err: any) => {
                let data = err.response.data;
                Toast.fire({
                    icon: 'error',
                    title: "Houve um erro ao reenviar o codigo."
                })
            });
        } else {
            Toast.fire({
                icon: 'error',
                title: 'Preenxa o campo E-mail.'
                })
        }

    }

    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let confirm = { email: data.get('email'), code: data.get('code') }

        if (!email || !code) {
            Toast.fire({
                icon: 'error',
                title: 'Preenxa os campos acima.'
                })
        } else{
            api.post('/confirmation-code', confirm)
            .then((response: any) => {
                Toast.fire({
                    icon: 'success',
                    title: "Email confirmado com sucesso."
                })

                setTimeout(function () {
                    window.location.pathname = "/";
                }, 300);
            })
            .catch((err: any) => {
                let data = err.response.data;
                                
                if (typeof data == 'object') {
                if ('pretty_error' in data) {
                    Toast.fire({
                        icon: 'error',
                        title: data['pretty_error']
                        })
                }
                }

                if (typeof data == 'string') {
                if (data.includes('ExpiredCodeException') ) {
                    Toast.fire({
                    icon: 'question',
                    title: 'Codigo de condirmação exirado, já confirmou o e-mail antes?'
                    })
                }
                }

            });
    }
    };

    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <Avatar sx={{ m: 1, bgcolor: 'white' }}>
                <img src="/Img/logo.svg" alt={"Logo"} />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    defaultValue={window.sessionStorage.email}
                    onChange = {((e)=>{email=e.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    name="code"
                    label="Codigo de confirmação"
                    type="number"
                    id="code"
                    onChange = {((e)=>{code=e.target.value})}
                    />
                </Grid>
                </Grid>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                Confirmar
                </Button>
                <Grid container justifyContent="flex-end">
                <Grid item>
                    <Link href="/" variant="body2">
                    
                    </Link>
                </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs>
                    <Link href="/" variant="body2">
                    Já confirmou o e-mail? Faça login
                    </Link>
                    </Grid>
                    <Grid item>
                    <Link onClick={() => reenviarCodigo()} href="#" variant="body2">
                        {"Reenviar codigo"}
                    </Link>
                    </Grid>
                </Grid>
            </Box>
            </Box>
        </Container>
        </ThemeProvider>
    );
}