import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // Você pode escolher o tema que preferir
import { Portuguese } from 'flatpickr/dist/l10n/pt';

interface IProps {
    value: Date[];
    onChange: Function;
}
const DatePicker: React.FC<IProps> = (props : IProps) => {

    return (
        <div>
            <Flatpickr
                options={{
                    mode: 'multiple',
                    dateFormat: 'd-m-Y',
                    locale: Portuguese
                }}
                style={{
                    color: 'black',
                }}
                value={props.value}
                onChange={(dates: Date[]) => props.onChange(dates)}
                placeholder="Escolha os dias"

            />
        </div>
    );
};

export default DatePicker;
