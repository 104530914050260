import React, { createContext, useContext, useState } from 'react';

let defaultSistema = window.localStorage.getItem('last_sistema_id') || null;

const SistemaSelecionadoContext: React.Context<{ sistemaSelecionado: string | null; setSistemaSelecionado: React.Dispatch<React.SetStateAction<string | null>>; }> = createContext<{ sistemaSelecionado: string | null; setSistemaSelecionado: React.Dispatch<React.SetStateAction<string | null>>; }>({
    sistemaSelecionado: defaultSistema,
    setSistemaSelecionado: () => { },
}
);

export const useSistemaSelecionado = () => useContext(SistemaSelecionadoContext);

export const SistemaSelecionadoProvider = ({ children }: { children: React.ReactNode }) => {
  const [sistemaSelecionado, setSistemaSelecionado] = useState<string | null>(window.localStorage.getItem('last_sistema_id'));

  return (
    <SistemaSelecionadoContext.Provider value={{ sistemaSelecionado, setSistemaSelecionado }}>
      {children}
    </SistemaSelecionadoContext.Provider>
  );
};