import React, { useState } from 'react';
import { AppBar, Toolbar, FormControl, InputLabel, Select, MenuItem, CircularProgress, Box, Typography, Link } from '@mui/material';
import { useSistemaSelecionado } from '../../../Contexts/BaseContext';
import GrupoDispositivosRepo from '../../../Services/GrupoDispositivosRepo';
import Toast from '../../../Services/Toast';
import { useQuery } from '@tanstack/react-query';
import GlobalDataRepo, { GlobalData } from '../../../Services/GlobalDataRepo';
import GaugeChart from 'react-gauge-chart';
import { Gauge, gaugeClasses } from '@mui/x-charts';

export default function GestaoAvista() {
    const { sistemaSelecionado } = useSistemaSelecionado();

    const [group, setGroup] = useState('*');
    const [eixo, setEixo] = useState('Horizontal');
    const [unidade, setUnidade] = useState('g');

    const { data: grupos = [], isLoading: isLoadingGrupos, isFetching: isFetchingGrupos } = useQuery({
        queryKey: ['grupos', sistemaSelecionado],
        queryFn: () => GrupoDispositivosRepo.getAll(sistemaSelecionado!).catch((err: any) => {
            if (err.response?.status === 403) {
                Toast.error("Você não tem permissão para acessar essa página!");
            } else {
                Toast.error("Erro ao carregar grupos de dispositivos");
            }
        }),
        enabled: !!sistemaSelecionado,
        staleTime: Infinity
    });

    const { data: dataGlobalData = [], isLoading: isLoadingGlobalData, isFetching: isFetchingGlobalData } = useQuery({
        queryKey: ['LatestGlobalData', sistemaSelecionado],
        queryFn: () => GlobalDataRepo.LatestGlobalData(sistemaSelecionado!),
        enabled: !!sistemaSelecionado,
        refetchInterval: 120000,
        refetchIntervalInBackground: true,
        placeholderData: [],
    });

    try {
        dataGlobalData.sort((a: any, b: any) => {
            let aF = a[`${eixo}(${unidade})`] as number | string;
            let bF = b[`${eixo}(${unidade})`] as number | string;

            if (typeof a === 'string') {
                aF = parseFloat(a);
            }
            if (typeof b === 'string') {
                bF = parseFloat(b);
            }
            if (aF > bF) return -1;
            if (aF < bF) return 1;
            return 0;
        });
    } catch (e) {

    }

    let dataToShow = dataGlobalData;

    if (group !== '*') {
        dataToShow = dataGlobalData.filter((data: GlobalData) => grupos.find((grupo) => grupo.id === group)?.dispositivos.includes(data.dispositivo_id) ?? false);
    }

    return (
        <>
            <AppBar elevation={0} position="static" sx={{ backgroundColor: 'white', borderBottom: '1px solid #ccc', padding: 1 }}>
                <Toolbar>
                    <FormControl variant="outlined" sx={{ minWidth: 120, margin: '0 10px' }}>
                        <InputLabel>Grupo</InputLabel>
                        <Select value={group} onChange={(e) => setGroup(e.target.value)} label="Grupo">
                            <MenuItem value="*">Todos</MenuItem>
                            {isLoadingGrupos || isFetchingGrupos ? (
                                <MenuItem disabled>
                                    <CircularProgress size={24} />
                                </MenuItem>
                            ) : (
                                grupos.map((grupo) => (
                                    <MenuItem key={grupo.id} value={grupo.id}>
                                        {grupo.nome}
                                    </MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" sx={{ minWidth: 120, margin: '0 10px' }}>
                        <InputLabel>Eixo</InputLabel>
                        <Select value={eixo} onChange={(e) => setEixo(e.target.value)} label="Eixo">
                            <MenuItem value="Horizontal">Horizontal</MenuItem>
                            <MenuItem value="Axial">Axial</MenuItem>
                            <MenuItem value="Vertical">Vertical</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" sx={{ minWidth: 120, margin: '0 10px' }}>
                        <InputLabel>Unidade</InputLabel>
                        <Select value={unidade} onChange={(e) => setUnidade(e.target.value)} label="Unidade">
                            <MenuItem value="g">g</MenuItem>
                            <MenuItem value="mm/s">mm/s</MenuItem>
                            <MenuItem value="mg">mg</MenuItem>
                        </Select>
                    </FormControl>
                </Toolbar>
            </AppBar>
            <Box sx={{ padding: 2, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {isLoadingGlobalData || isFetchingGlobalData ? (
                    <CircularProgress />
                ) : (
                    dataToShow.map((data: any, index: number) => (
                        <ValueGauge dispositivo_id={data.dispositivo_id} value={data[`${eixo}(${unidade})`]} index={index} tag={data.tag} unidade={unidade} />
                    ))
                )}
            </Box>
        </>
    );
}

function ValueGauge({ value, index, tag, unidade, dispositivo_id }: { value: string | number, index: number, tag: string, unidade: string, dispositivo_id: string }) {
    let valueF: number = value as number;

    if (typeof value === 'string') {
        valueF = parseFloat(value);
    }

    let valueMax = parseInt(String(valueF)) + 2;
    if (unidade === "mg") valueMax = parseInt(String(valueF)) + 200;

    valueF = Math.abs(valueF);
    return (
        <Box key={index} sx={{ margin: 2, height: '130px', width: "130px" }}>
            <Gauge
                value={valueF}
                startAngle={-110}
                endAngle={110}
                valueMax={valueMax}
                sx={(theme) => ({
                    [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 30,
                        transform: 'translate(0px, 10px)',
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                        fill: getGaugeColor({ value: valueF, unidade: unidade }),
                    },
                    [`& .${gaugeClasses.referenceArc}`]: {
                        fill: theme.palette.grey[300],
                    },
                })}
                text={({ value }) => { if (unidade === "mg") return `${value}`; else return `${value?.toFixed(2)}`; }}
                title={tag}
            />
            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                <Link variant="body2" textAlign="center" onClick={() => { window.localStorage.setItem("last_dispositivo_id", dispositivo_id); }} href="/analise">
                    {tag}
                </Link>
            </Box>
        </Box>
    );
}

function getGaugeColor({ value, unidade }: { unidade: string, value: number }) {
    switch (unidade) {
        case 'g':
            if (value >= 6) return 'rgb(143, 59, 184)';
            if (value >= 4.5) return 'rgb(196, 22, 42)';
            if (value >= 1.02) return 'rgb(224, 180, 0)';
            return 'rgb(55, 135, 45)';
        case 'mm/s':
            if (value >= 10) return 'rgb(143, 59, 184)';
            if (value >= 6) return 'rgb(196, 22, 42)';
            if (value >= 4.5) return 'rgb(224, 180, 0)';
            return 'rgb(55, 135, 45)';
        case 'mg':
            if (value >= 6000) return 'rgb(143, 59, 184)';
            if (value >= 4500) return 'rgb(196, 22, 42)';
            if (value >= 1750) return 'rgb(224, 180, 0)';
            return 'rgb(55, 135, 45)';
    }
}