import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import api from '../../Services/Api';
import Swal from 'sweetalert2';

const theme = createTheme();


export default function ForgotPassword() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast: { addEventListener: (arg0: string, arg1: any) => void; }) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    let email: string = window.sessionStorage.email;
    let code: string = "";

    function reenviarCodigo() {

        api.post("/forgot-password", { email: email }).then((result: any) => {
            Toast.fire({
              icon: 'success',
              title: 'Código de confirmação enviado.'
            })
          }).catch((err: any) => {
            Toast.fire({
              icon: 'error',
              title: 'Erro ao enviar código.'
            })
          });

    }


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let confirm = { email: data.get('email'), confirm_code: data.get('code'), new_password: data.get("senha") }

        api.post('/confirm-forgot-password', confirm)
            .then((response: any) => {
                Toast.fire({
                    icon: 'success',
                    title: 'Senha alterada com sucesso.'
                })
                setTimeout(function () {
                    window.location.pathname = "/";
                }, 100);
            })
            .catch((err: any) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Código ou e-mail incorretos.'
                })
            });

    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {/* <Avatar sx={{ m: 1, bgcolor: 'white' }}>
                        <img src="/Img/logo.svg" />
                    </Avatar> */}
                    <Typography component="h1" variant="h5">
                        Esqueci minha senha
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    defaultValue={window.sessionStorage.email}
                                    autoComplete="email"
                                    onChange={((e) => { email = e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="code"
                                    label="Codigo de confirmação"
                                    type="number"
                                    id="code"
                                    onChange={((e) => { code = e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="senha"
                                    label="Nova Senha"
                                    type="password"
                                    id="senha"
                                    onChange={((e) => { code = e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Confirmar
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/" variant="body2">

                                </Link>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/" variant="body2">
                                    Já redefiniu a senha? Faça login
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link onClick={(e) => {e.preventDefault();reenviarCodigo()}} href="#" variant="body2">
                                    {"Reenviar codigo"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}